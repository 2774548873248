import React from "react";
import "./Landing.style.css";

function LandingV2() {
  return (
    <div className='prelisa_home'>
      <div className='content_container'>
        <div className='prelisa_logo'>
          <svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M5.56445 5.56543H58.434V39.7104H40.6253V23.1886H23.3731V58.435H5.56445V5.56543Z' fill='#DC143C' />
            <path
              d='M32.2775 47.9712C35.9658 47.9712 38.9558 45.0124 38.9558 41.3625C38.9558 37.7127 35.9658 34.7538 32.2775 34.7538C28.5892 34.7538 25.5992 37.7127 25.5992 41.3625C25.5992 45.0124 28.5892 47.9712 32.2775 47.9712Z'
              fill='#DC143C'
            />
          </svg>
        </div>
        <h1>I create, hence I connect</h1>
        <p>Welcome to my little space on the internet.</p>
        <p>I’m Prelisa Dahal—a product designer, problem solver, and blockchain enthusiast who loves creating intuitive and scalable digital experiences.</p>
        <p>
          For the past four years, I’ve been helping businesses design software that’s not just functional, but also user-friendly, scalable, and built with people in mind. My expertise lies in
          understanding problems deeply, crafting thoughtful solutions, prototyping, testing, and refining experiences that make an impact.
        </p>

        <p>
          Right now, I’m pursuing a Master’s in Computer Science with a focus on blockchain because I believe in its potential to reshape digital identity, transparency, and ownership. I’ve also been
          actively contributing as a designer to various blockchain projects, helping bridge the gap between complex technology and seamless user experiences.
        </p>

        <p>
          Technology has always fascinated me—not just for what it can do, but for how it connects us. I live 7,543 miles away from home, and my parents still marvel at how video calls make the
          distance feel smaller. That’s the kind of impact I want to create—technology that feels effortless, intuitive, and meaningful.
        </p>
        <p>This website is a work in progress, just like me. I’m constantly learning, iterating, and improving. Thanks for stopping by—I appreciate it! Excited to share more soon.</p>
        <div className='prelisa_socials'>
          <a href='https://linkedin.com/in/prelisa' target='_blank'>
            Linkedin
          </a>
          <a href='https://drive.google.com/file/d/14lqDARll6I8zxHOXL5KuEQnhKavdvKDr/view?usp=sharing'>Resume</a>
        </div>
        <div className='lets_connect'>
          <h1 className='lets_connect_info'>Let's get to know each other.</h1>
          <a href='mailto:prelisa.dahal.biz@gmail.com' className='lets_connect_link'>
            Get in touch.
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandingV2;
