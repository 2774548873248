import React from "react";
import "./App.css";
import LandingV2 from "./Pages/LandingV2/Landing.page";
function App() {
  return (
    <div className='main-container'>
      <LandingV2 />
    </div>
  );
}

export default App;
